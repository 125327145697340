import React from "react";
import PropTypes from "prop-types";

import { Helmet } from "react-helmet";

import Theme, { theme } from "../../packages/themes/src/Startup";
import { Copyright, Legal } from "../../packages/themes/src/Startup/containers";

import preview from "../../packages/themes/src/Startup/assets/preview.jpg";

import SEO from "../components/SEO";

const Imprint = ({ url }) => (
  <Theme>
    <Legal name="imprint" />

    <Copyright />
  </Theme>
);

Imprint.propTypes = {
  url: PropTypes.string,
};

Imprint.defaultProps = {
  url: "https://maai.digital",
};

export default Imprint;
